import { HUB } from "../../hub";
import { Gems } from "../../gems";
import router from "../../router";

const state = {
  user: {},
  apps: {},
  entityLogo: '',
  showMFA: false
};

const getters = {
  User: (state) => state.user,
  Apps: (state) => state.apps,
  EntityLogo: (state) => state.entityLogo,
  ShowMFA: state => state.showMFA
};

const actions = {

  async HasAccess({ commit }, credentials) {
    let response
    try {
      response = await HUB.CallBackEnd({
        action: HUB.Action('login'), data: {
          user: credentials["username"],
          pass: credentials["password"],
          forgotPass: credentials["forgotPass"],
          mfa_confirmed: credentials['mfa_confirmed']
        }, options: { CUSTOM_VALIDATION: true }
      })
    } catch {
      Gems.Telegram('Ocorreu um erro', "tele-not-allowed");
      return
    }

    if (response.data?.pass_change_expired == 1) {
      Gems.Telegram(response.msg, "tele-info", "");
      return;
    }

    if (response.data?.mfa_expired == 1) {
      Gems.Telegram(response.msg, "tele-info", "");
      commit('showMFA', true)
      return;
    }

    if (credentials.forgotPass)
      return response

    if (response.error == false) {

      sessionStorage.setItem('logged_user_id', response.data.logged_user_id)
      const params = window.location.search

      if (params) {
        const redirectApp = params.substring(1)
        const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
        if (base64regex.test(redirectApp))
          window.location.href = `https://${window.atob(redirectApp)}`
      }

      router.push({ path: "/hub" })
    } else {
      Gems.Telegram(response.msg, "tele-not-allowed")
      return
    }
  },

  async Authentication({ state }) {
    const response = await HUB.CallBackEnd({ action: HUB.Action("router-check") })
    const entity = response.data['entity']

    if (entity)
      localStorage.setItem("entity", entity);

    state.user = response.data?.user
    state.apps = response.data?.apps
    state.entityLogo = response.data?.entity_logo

    return response.data.status
  },

};

const mutations = {

  showMFA: (state, show) => {
    state.showMFA = show;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
