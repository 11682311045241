<script setup>
import { useStore } from "vuex";
import router from "../router"

const store = useStore()

function OpenDivMenu() {
  const divMenu = document.getElementById('div-menu')
  const menuContent = document.getElementById('div-menu-content')

  setTimeout(() => {
    if (divMenu.classList.contains('opened'))
      menuContent.classList.remove('hidden')
  }, 150)
  
  divMenu.classList.toggle('opened')

  if (!divMenu.classList.contains('opened')) {
    divMenu.classList.add('closed')
  } else {
    divMenu.classList.remove('closed')
  }

  if (divMenu.classList.contains('closed'))
    menuContent.classList.add('hidden')
}

</script>


<template>
  <div id="div-menu">
    <i @click="OpenDivMenu()" class="fa fa-bars"></i>
    <div id="div-menu-content" class="hidden">
      <div :class="{'div-menu-white' : router.currentRoute.value.name == 'Hub'}" @click="router.push('hub')">HOME</div>
      <div :class="{'div-menu-white' : router.currentRoute.value.name == 'Access'}" v-if="store.getters.User.admin == 1" @click="router.push('access')">ACESSOS</div>
      <div :class="{'div-menu-white' : router.currentRoute.value.name == 'Import'}" v-if="store.getters.User.admin == 1" @click="router.push('import')">TABELAS</div>
      <div :class="{'div-menu-white' : router.currentRoute.value.name == 'Hierarchy'}" v-if="store.getters.User.admin == 1" @click="router.push('hierarchy')">HIERARQUIA</div>
      <div :class="{'div-menu-white' : router.currentRoute.value.name == 'Licensing'}" v-if="store.getters.User.admin == 1" @click="router.push('licensing')">LICENCIAMENTO</div>
      <div @click="router.push('logout')">SAIR</div>
    </div>
  </div>
</template>

<style scoped>
.div-menu-white {
  color: white;
}

#div-menu-content {
  margin-top: 20px;
}

#div-menu-content>div {
  cursor: pointer;
  font-weight: 600;
  padding: 15% 0 0 20%;
}

.fa-bars {
  font-size: 2em;
  cursor: pointer;
  transition: 200ms;
}

.opened > .fa-bars {
    font-size: 1.5em;
}

#div-menu > i {
  color: white;
}

#div-menu {
  padding: 16px;
  min-height: 100vh;
  min-width: 60px;
}

#div-menu.opened {
  animation-name: open-menu;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  background-color: #231f20 !important;
  color: #a976fd;
}

#div-menu.closed {
  animation-name: closed-menu;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
}

@keyframes open-menu {
  from {
    width: 0px;
  }

  to {
    width: 13vw;
    background-color: #00000033;
    color: var(--font-cirvalue-day);
    flex: none;
  }
}

@keyframes closed-menu {
  from {
    width: 13vw;
    background-color: #00000033;
    color: var(--font-cirvalue-day);
    flex: none;
  }
  
  to { width: 0; }
}

</style>
