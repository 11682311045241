<script setup>

import { onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue'
import router from '../router'
import { Gems } from '@/gems'
import store from "../store"
import { HUB } from '../hub'

const AnimateMenuClick = ref(null);
const entityData = ref({ entity_name: "" });
const defaultImage = require('../images/default.png');

let photoTooltip = `${store.getters.User.name}`
let currentIndex = 0
let images

onMounted(async () => {


    if (history.state?.change && history.state.message != null) {
        Gems.Telegram(history.state.message, 'tele-success')
        history.state.message = null
    }

    document.querySelector('body').style.overflowX = 'hidden'
    document.querySelector('body').style.backgroundColor = 'black'

    Object.values(store.getters.Apps).forEach(app => {

        if (app == '360' || app == 'comp' || app == 'kpi') {
            document.querySelector(`[app-name="${app}"]`).classList.remove('hidden')
            app = 'cirvalues'
        }

        const menu = document.querySelector(`.app-${app}`)

        if (menu && !menu.classList.contains('app-knolia'))
            menu.classList.remove('locked')
    })

    const res = await Gems.CallWS({ app: 'ceres', action: 'client-data', body: { entity: localStorage.getItem('entity') } })

    if (res.data && !res.error && res.data.length > 0) {
        entityData.value.entity_name = res.data[0].entity_name;
        photoTooltip = `${store.getters.User.name} | ${res.data[0].entity_name}`
    }

    Gems.Tooltip(document.getElementById('div-user-photo'), photoTooltip, 'tooltip')

    window.addEventListener('resize', HandleResize)
    await HandleResize()

    if (HUB.isAdmin() == 1)
        document.querySelector('.circle-4').classList.remove('locked')
    else
        document.querySelector('.circle-4').classList.add('locked')

    const firstAvailableApp = document.querySelector('.circle:not(.locked)')

    if (firstAvailableApp)
        AnimateMenuClick.value(firstAvailableApp)

    document
        .querySelectorAll('.menu-info')
        .forEach(menuInfo => {
            menuInfo.onmouseenter = (e) => {
                e.target.parentElement.querySelector('.circle-title').firstElementChild.style.color = '#2affff'
            }
        })

    document
        .querySelectorAll('.menu-info')
        .forEach(menuInfo => {
            menuInfo.onmouseleave = (e) => {
                e.target.parentElement.querySelector('.circle-title').firstElementChild.removeAttribute('style')
            }
        })

    document
        .querySelectorAll('.circle-title')
        .forEach(title => {
            title.firstElementChild.style.color = '#fff'
        })

    images = document.querySelectorAll(".div-carousel")
    setTimeout(() => setInterval(ChangeImage, 10000), 2000)
})

onBeforeUnmount(() => window.removeEventListener('resize', HandleResize))
onUnmounted(() => document.querySelector('body').removeAttribute('style'))

function ChangeImage() {
    images[currentIndex].classList.remove("active");
    currentIndex = (currentIndex + 1) % images.length;
    images[currentIndex].classList.add("active");
}


async function HandleResize() {
    let circleControlComposableString = 'circleControl'

    if (window.innerWidth <= 845) {
        circleControlComposableString = 'circleControlMobile'

        document.querySelectorAll(`[app-name]`)
            .forEach(element => {
                Gems.Tooltip(element, GetAppTooltip(element.getAttribute('app-name')), 'tooltip')
            })
    }


    await import(`../composables/hub/${circleControlComposableString}`)
        .then(composable => AnimateMenuClick.value = composable.default)
        .catch(error => console.error('failing importing composable', error))
}

function GetAppTooltip(appName) {
    let text = 'Em breve'

    switch (appName) {
        case 'cirvalues':
            text = "CIRVALUE - Avaliação de comportamentos, performance e KPI's"
            break;
        case '360':
            text = 'CIRVALUE 360 - Análise Colaborativa e Avaliação Contínua'
            break;
        case 'comp':
            text = 'CIRVALUE COMP - Gestão de Comportamentos Profissionais'
            break;
        case 'kpi':
            text = 'CIRVALUE KPI - Gestão de Performance'
            break;
        case 'intime':
            text = 'INTIME - Gestão de Tarefas e Tempos'
            break;
        case 'knolia':
            text = 'KNOLIA - Gestão de Competências'
            break;
        case 'hub':
            text = 'Administração'
            break;
    }

    return text
}

</script>

<template>
    <div class="container">

        <div class="header">
            <div id="div-logout">
                <div class="div-user">
                    <span>{{ entityData.entity_name }}</span>
                    <img id="div-user-photo" class="div-user-photo" :src="store.getters.User.photo"
                        @error="$event.target.src = defaultImage">
                    <span class="logout" @click="router.push('logout')">Sair</span>
                </div>
            </div>

            <div class="top">
                <img src="../images/inpeople.png" id="logo-inpeople">
                <img src="../images/logo_admin.png" id="logo-inpeople-admin">
            </div>
        </div>

        <div class="main">
            <div class="div-carousel active" id="div-carousel-1"></div>
            <div class="div-carousel" id="div-carousel-2"></div>
            <div class="div-carousel" id="div-carousel-3"></div>
            <div class="div-carousel" id="div-carousel-4"></div>

            <span class="span-main-title">
                <span>Olá,</span>
                <br>
                <span class="span-main-title-middle">Bom estar</span>
                <br>
                <span class="span-main-title-lower">de volta.</span>
            </span>

            <div class="circles">

                <div id="circle-1" app-name="cirvalues" class="circle app-cirvalues locked"
                    @click.prevent="AnimateMenuClick($event.target)">
                    <span>
                        <div class="circle-title">
                            <span class="cursor-pointer"
                                @click.stop="AnimateMenuClick($event.target.parentElement.parentElement.parentElement)">
                                AVALIAÇÃO DE DESEMPENHO <i class="fas fa-lock"></i>
                            </span>
                            <span class="img identity-cirvalues"
                                @click.stop="AnimateMenuClick($event.target.parentElement.parentElement.parentElement)"></span>
                        </div>

                        <div @click.stop class="menu-info cirvalues">
                            <a class="hidden" target="_blank" :href="`https://${Gems.EnvPrefix()}kpi.inpeoplehub.com`"
                                app-name="kpi">Indicadores e Objetivos <i class="fas fa-arrow-right"></i></a>
                            <a class="hidden" target="_blank" :href="`https://${Gems.EnvPrefix()}comp.inpeoplehub.com`"
                                app-name="comp">Comportamentos
                                profissionais <i class="fas fa-arrow-right"></i></a>
                            <a class="hidden" target="_blank" :href="`https://${Gems.EnvPrefix()}360.inpeoplehub.com`"
                                app-name="360">Feedback 360° <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </span>
                </div>

                <div id="circle-2" app-name="intime" class="circle app-intime locked"
                    @click.prevent="AnimateMenuClick($event.target)">
                    <span @click.stop="AnimateMenuClick($event.target.parentElement)">

                        <div class="circle-title"
                            @click.stop="AnimateMenuClick($event.target.parentElement.parentElement.parentElement)">
                            <span> TAREFAS E TEMPOS <i class="fas fa-lock"></i> </span>
                            <span class="img identity-intime"></span>
                        </div>


                        <div @click.stop class="menu-info">
                            <a target="_blank"
                                :href="`https://${Gems.EnvPrefix()}intime.inpeoplehub.com/private/workflow`">Tarefas <i
                                    class="fas fa-arrow-right"></i></a>
                            <a target="_blank"
                                :href="`https://${Gems.EnvPrefix()}intime.inpeoplehub.com/private/teams`">Equipas <i
                                    class="fas fa-arrow-right"></i></a>
                            <a target="_blank"
                                :href="`https://${Gems.EnvPrefix()}intime.inpeoplehub.com/private/time`">Tempos <i
                                    class="fas fa-arrow-right"></i></a>
                            <a target="_blank"
                                :href="`https://${Gems.EnvPrefix()}intime.inpeoplehub.com/private/summary`">Síntese <i
                                    class="fas fa-arrow-right"></i></a>
                        </div>
                    </span>
                </div>

                <div id="circle-3" app-name="knolia" class="circle app-knolia locked">
                    <span>
                        <div class="circle-title">
                            <span> GESTÃO DE COMPETÊNCIAS <i class="fas fa-lock"></i> </span>
                            <span class="img identity-knolia"></span>
                        </div>

                        <div @click.stop class="menu-info">
                            <a target="_blank"
                                :href="`https://${Gems.EnvPrefix()}knolia.inpeoplehub.com/search-users`">Gerir pessoas <i
                                    class="fas fa-arrow-right"></i></a>
                            <a target="_blank" :href="`https://${Gems.EnvPrefix()}knolia.inpeoplehub.com/job-tasks`">Funções
                                <i class="fas fa-arrow-right"></i></a>
                            <a target="_blank"
                                :href="`https://${Gems.EnvPrefix()}knolia.inpeoplehub.com/skills-tree`">Competências <i
                                    class="fas fa-arrow-right"></i></a>
                            <a target="_blank"
                                :href="`https://${Gems.EnvPrefix()}knolia.inpeoplehub.com/tasks-tree`">Atividades <i
                                    class="fas fa-arrow-right"></i></a>
                        </div>
                    </span>
                </div>

                <div id="circle-4" app-name="hub" class="circle circle-4 locked app-hub"
                    @click.prevent="AnimateMenuClick($event.target)">
                    <span @click.stop="AnimateMenuClick($event.target.parentElement)">

                        <div class="circle-title"
                            @click.stop="AnimateMenuClick($event.target.parentElement.parentElement.parentElement)">
                            <span> ADMINISTRAÇÃO <i class="fas fa-lock"></i> </span>
                        </div>

                        <div @click.stop class="menu-info">
                            <a @click="router.push('access')">Acessos <i class="fas fa-arrow-right"></i></a>
                            <a @click="router.push('import')">Tabelas <i class="fas fa-arrow-right"></i></a>
                            <a @click="router.push('hierarchy')">Hierarquia <i class="fas fa-arrow-right"></i></a>
                            <a @click="router.push('licensing')">Licenciamento <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </span>
                </div>
            </div>
        </div>

        <div class="footer">People inspiring <br /> performance</div>
    </div>
</template>

<style>
:root {
    --normal-width: 900px;
    --normal-height: 900px;
    --normal-radius: 455px;

    --medium-width: 600px;
    --medium-height: 600px;
    --medium-radius: 303px;

    --medium-sm-width: 500px;
    --medium-sm-height: 500px;
    --medium-sm-radius: 253px;

    --small-width: 450px;
    --small-height: 450px;
    --small-radius: 225px;
}

@media only screen and (max-width: 800px) {
    .div-master-footer {
        display: none;
    }
}
</style>

<style scoped>
i {
    pointer-events: none;
}

.div-carousel {
    background-position: top left;
    background-size: cover;
    background-color: #000;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    transition: opacity 5s ease-in-out;
}

.div-carousel.active {
    opacity: 1;
}

#div-carousel-1 {
    background-image: url('../images/1.png');
}

#div-carousel-2 {
    background-image: url('../images/2.jpeg');
}

#div-carousel-3 {
    background-image: url('../images/3.webp');
}

#div-carousel-4 {
    background-image: url('../images/4.png');
}

.container {
    min-height: 100vh;
    color: white;
    overflow: hidden;
}

.hidden {
    display: none !important;
}

.span-main-title {
    position: absolute;
    font-size: 6rem;
    margin: 10% 0 0 5%;
    top: 50%;
    left: 28%;
    font-family: "Space Grotesk", sans-serif;
    font-weight: 600;
    text-shadow: 0px 0px 5px #00000090;
}

.span-main-title-lower,
.span-main-title-middle {
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
}

.span-main-title-lower {
    position: absolute;
    margin-top: -33px;
}

.main {
    width: var(--normal-width);
    height: var(--normal-height);
    position: absolute;
    top: -250px;
    left: -250px;
    margin: auto;
    border-radius: 50%;
}

.main::before {
    content: "";
    width: calc(var(--normal-width) - 45px);
    height: calc(var(--normal-height) - 45px);
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    padding: 30px;
    background: var(--default-gradient-border);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: 1;
}

.circle {
    width: 6%;
    height: 6%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
    background-position: top;
    background-size: contain;
    background-color: #000000aa;
    background-repeat: no-repeat;
    border: 0.5rem solid transparent;
    display: flex;
    align-items: center;
    z-index: 99;
}

.circle span {
    position: absolute;
    color: white;
    width: max-content;
    font-family: "Space Grotesk", sans-serif;
    pointer-events: none;
}

.app-knolia {
    background-image: url('../images/logo_knolia.png');
}

.app-cirvalues {
    background-image: url('../images/logo_cirvalue.png');
}

.app-intime {
    background-image: url('../images/logo_intime.png');
}

.app-hub {
    background-image: url('../images/logo_hub.png');
}

.img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 150px !important;
    padding: 18px;
    display: none;
    right: 12%;
    pointer-events: none !important;
}

.identity-knolia {
    background-image: url('../images/identity_knolia.png');
}

.identity-cirvalues {
    background-image: url('../images/identity_cirvalue.png');
}

.identity-intime {
    background-image: url('../images/identity_intime.png');
}

#circle-1 {
    transform: rotate(calc(0deg + (35deg * 0))) translate(calc(var(--normal-radius) - 5px)) rotate(calc(0deg + (35deg * 0)));
    width: 12%;
    height: 12%;
}

#circle-1 div {
    font-size: 1.8em;
    transform: rotate(calc(0deg + (35deg * 0))) translate(125px) rotate(calc(0deg + (35deg * 0)));
}

#circle-1 span .menu-info {
    display: grid;
}

#circle-1 span .menu-info a {
    cursor: pointer;
}

.circle-title {
    pointer-events: none;
}

#circle-1 .circle-title {
    z-index: 2;
    position: relative;
}

#circle-1 span .cirvalues {
    display: flex !important;
}

#circle-2 {
    transform: rotate(calc(0deg + (35deg * 1))) translate(calc(var(--normal-radius))) rotate(calc(0deg + (35deg * -1)));
    width: 9%;
    height: 9%;
}

#circle-2 div {
    font-size: 1.8em;
    transform: rotate(calc(0deg + (35deg * 1))) translate(90px) rotate(calc(0deg + (35deg * -1)));
}

#circle-3 {
    transform: rotate(calc(0deg + (35deg * 2))) translate(calc(var(--normal-radius) - 3px)) rotate(calc(0deg + (35deg * -2)));
    border: 0.4rem solid transparent;
    width: 7%;
    height: 7%;
}

#circle-3 div {
    font-size: 1.6em;
    transform: rotate(calc(0deg + (35deg * 2))) translate(60px) rotate(calc(0deg + (35deg * -2)));
}

#circle-4 {
    transform: rotate(calc(0deg + (35deg * 3))) translate(calc(var(--normal-radius) - 7px)) rotate(calc(0deg + (35deg * -3)));
    border: 0.35rem solid transparent;
}

#circle-4 div {
    font-size: 1.4em;
    transform: rotate(calc(0deg + (35deg * 3))) translate(60px) rotate(calc(0deg + (35deg * -3)));
}

#circle-1 .circle-title {
    width: calc(100vw - 750px);
}

#circle-1 .circle-title .img {
    display: unset;
}

#circle-1 .circle-title span {
    font-size: 1.5em;
}

.circle-title span {
    flex: 1;
    pointer-events: all;
}

.circle-title span:first-of-type {
    position: unset;
}

.menu-info {
    position: absolute;
    width: -moz-max-content;
    top: 0px;
    left: -20px;
    width: max-content;
    grid-template-columns: 1fr 1fr;
    gap: 10px 20px;
    padding: 60px 0 0 40px;
    font-weight: normal !important;
    display: none;
    z-index: 1;
    pointer-events: all;
}

.cirvalues {
    flex-direction: column;
}

.menu-info a {
    color: white;
    text-decoration: none;
    font-size: .8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: all;
}

.circle .circle-title:hover span:first-of-type,
.circle a:hover,
.div-user .logout:hover {
    color: var(--default-hover-color) !important;
}

.fa-arrow-right {
    font-size: .7em;
    rotate: 45deg;
    margin-left: 10px;
    margin-top: 5px;
}

.footer {
    font-size: 5em;
    color: white;
    position: absolute;
    right: 3%;
    bottom: 7%;
    word-wrap: break-word;
    width: 50%;
    text-align: end;
    opacity: 0.3;
    line-height: 75px;
}

#div-logout {
    position: fixed;
    font-size: 2em;
    color: white;
    z-index: 99;
    font-weight: 500;
    line-height: 1lh;
    top: 4%;
    right: 3%;
}

.top {
    position: absolute;
    width: 180px;
    opacity: 0.3;
}

.top img {
    width: 100%;
}

#logo-inpeople-admin {
    display: none;
}

.header {
    margin-left: calc(var(--normal-width) - 200px);
    width: calc(100vw - var(--normal-width) - 200px);
    position: absolute;
    top: 4%;
}

.locked,
.locked span {
    pointer-events: none;
    cursor: default;
}

.locked .circle-title:hover span:first-of-type,
.locked a:hover {
    color: inherit !important;
}

.locked span {
    opacity: .5;
}

.fa-lock {
    display: none;
}

.locked .fa-lock {
    display: initial;
}

.div-user {
    align-self: center;
    display: flex;
    align-items: center;
    gap: 10px;
}

.div-user span:first-child {
    margin-right: 10px;
}

.div-user span {
    color: #fff;
    text-shadow: 0 0 5px #00000090;
    font-size: 1rem;
    margin-left: 10px;
}

.logout {
    cursor: pointer;
}

.div-user-photo {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
    background-size: cover;
    background-position: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, .5);
    -o-object-fit: cover;
    object-fit: cover;
}

@media only screen and (max-width: 1435px),
screen and (max-height: 780px) {
    .main {
        width: var(--medium-width);
        height: var(--medium-height);
        top: -120px;
        left: -170px;
    }

    .main::before {
        content: "";
        width: calc(var(--medium-width) - 27px);
        height: calc(var(--medium-width) - 32px);
        padding: 20px;
    }

    .circle {
        border: 0.35rem solid transparent;
    }

    #circle-1 {
        transform: rotate(calc(0deg + (35deg * 0))) translate(var(--medium-radius)) rotate(calc(0deg + (35deg * 0)));
    }

    #circle-2 {
        transform: rotate(calc(0deg + (35deg * 1))) translate(var(--medium-radius)) rotate(calc(0deg + (35deg * -1)));
    }

    #circle-3 {
        transform: rotate(calc(0deg + (35deg * 2))) translate(calc(var(--medium-radius) - 3px)) rotate(calc(0deg + (35deg * -2)));
        border: 0.28rem solid transparent;
    }

    #circle-4 {
        transform: rotate(calc(0deg + (35deg * 3))) translate(calc(var(--medium-radius) - 7px)) rotate(calc(0deg + (35deg * -3)));
        border: 0.24rem solid transparent;
    }

    .div-user-photo {
        height: 2.5rem;
        width: 2.5rem;
    }

    #circle-1 .circle-title {
        width: calc(100vw - 506px);
    }

    .span-main-title {
        font-size: 3rem;
        top: 55%;
    }

    .span-main-title-lower,
    .span-main-title-middle {
        font-family: 'Poppins', sans-serif;
        font-size: 2.5rem;
    }

    .span-main-title-lower {
        margin-top: -23px;
    }

    .header {
        margin-left: calc(var(--medium-width) - 130px);
        width: calc(100vw - var(--medium-width) - 130px);
        position: absolute;
    }

    #circle-1 div {
        transform: rotate(calc(0deg + (35deg * 0))) translate(85px) rotate(calc(0deg + (35deg * 0)));
        font-size: 1.6em;
    }

    #circle-2 div {
        transform: rotate(calc(0deg + (35deg * 1))) translate(65px) rotate(calc(0deg + (35deg * -1)));
        font-size: 1.4em;
    }

    #circle-3 div {
        transform: rotate(calc(0deg + (35deg * 2))) translate(45px) rotate(calc(0deg + (35deg * -2)));
        font-size: 1.2em;
    }

    #circle-4 div {
        transform: rotate(calc(0deg + (35deg * 3))) translate(50px) rotate(calc(0deg + (35deg * -3)));
        font-size: 1em;
    }

    .footer {
        font-size: 3.9em;
    }
}

@media only screen and (max-width: 1130px) {
    .main {
        width: var(--medium-sm-width);
        height: var(--medium-sm-height);
        top: -95px;
        left: -150px;
    }

    .main::before {
        content: "";
        width: calc(var(--medium-sm-width) - 12px);
        height: calc(var(--medium-sm-width) - 15px);
        padding: 10px;
    }

    .header {
        margin-left: calc(var(--medium-sm-width) - 140px);
        width: calc(100vw - var(--medium-sm-width) - 140px);
        position: absolute;
    }

    .circle {
        border: 0.25rem solid transparent;
    }

    #circle-1 {
        transform: rotate(calc(0deg + (35deg * 0))) translate(var(--medium-sm-radius)) rotate(calc(0deg + (35deg * 0)));
    }

    #circle-2 {
        transform: rotate(calc(0deg + (35deg * 1))) translate(var(--medium-sm-radius)) rotate(calc(0deg + (35deg * -1)));
    }

    #circle-3 {
        transform: rotate(calc(0deg + (35deg * 2))) translate(calc(var(--medium-sm-radius) - 3px)) rotate(calc(0deg + (35deg * -2)));
        border: 0.2rem solid transparent;
    }

    #circle-4 {
        transform: rotate(calc(0deg + (35deg * 3))) translate(calc(var(--medium-sm-radius) - 5px)) rotate(calc(0deg + (35deg * -3)));
        border: 0.18rem solid transparent;
    }

    .div-user-photo {
        height: 2.5rem;
        width: 2.5rem;
    }

    #circle-1 .circle-title {
        width: calc(100vw - 380px);
    }

    .span-main-title {
        font-size: 2.5rem;
        top: 55%;
    }

    .span-main-title-lower,
    .span-main-title-middle {
        font-family: 'Poppins', sans-serif;
        font-size: 2rem;
    }

    .span-main-title-lower {
        margin-top: -13px;
    }

    .img {
        width: 125px !important;
        padding: 14px;
        right: 9%;
    }

    #circle-1 div {
        transform: rotate(calc(0deg + (35deg * 0))) translate(50px) rotate(calc(0deg + (35deg * 0)));
        font-size: 1.2em;
    }

    #circle-2 div {
        transform: rotate(calc(0deg + (35deg * 1))) translate(40px) rotate(calc(0deg + (35deg * -1)));
        font-size: 1em;
    }

    #circle-3 div {
        transform: rotate(calc(0deg + (35deg * 2))) translate(25px) rotate(calc(0deg + (35deg * -2)));
        font-size: .9em;
    }

    #circle-4 div {
        transform: rotate(calc(0deg + (35deg * 3))) translate(30px) rotate(calc(0deg + (35deg * -3)));
        font-size: .8em;
    }

    .footer {
        font-size: 3em;
    }

}

@media only screen and (max-width: 845px) {
    .footer {
        display: none;
    }

    .main {
        width: var(--small-width);
        height: var(--small-height);
        top: -95px;
        left: -100px;
    }

    .main::before {
        content: "";
        width: calc(var(--small-width) - 14px);
        height: calc(var(--small-width) - 15px);
        padding: 10px;
    }

    .header {
        width: 100vw;
        position: absolute;
    }

    .circle {
        border: 0.3rem solid transparent;
    }

    #circle-1 {
        transform: rotate(calc(0deg + (35deg * 3))) translate(var(--small-radius)) rotate(calc(0deg + (35deg * -3)));
        width: 13%;
        height: 13%;
    }

    #circle-2 {
        transform: rotate(calc(0deg + (35deg * 2))) translate(var(--small-radius)) rotate(calc(0deg + (35deg * -2)));
        width: 11%;
        height: 11%;
    }

    #circle-3 {
        transform: rotate(calc(0deg + (35deg * 1))) translate(calc(var(--small-radius) + 3px)) rotate(calc(0deg + (35deg * -1)));
        border: 0.24rem solid transparent;
        width: 9%;
        height: 9%;
    }

    #circle-4 {
        transform: rotate(calc(0deg + (35deg * 0))) translate(calc(var(--small-radius) + 2px)) rotate(calc(0deg + (35deg * 0)));
        border: 0.2rem solid transparent;
        width: 8%;
        height: 8%;
    }

    .circle:not(#circle-1) div {
        display: none;
    }

    #circle-1 div {
        transform: rotate(calc(0deg + (35deg * -3))) translate(-90px) rotate(calc(0deg + (35deg * 3))) !important;
        display: flex;
        font-size: unset;
    }

    #circle-1 .circle-title span {
        font-size: 1.6em;
    }

    .div-user {
        flex-direction: column;
    }

    .div-user-photo {
        height: 2.5rem;
        width: 2.5rem;
    }

    .menu-info {
        grid-template-columns: none;
        margin: 55px 0 0 15px;
        gap: 10px;
        padding: 0px 0px 60px 0px;
        top: initial;
        left: initial;
        font-size: 1.3em !important;
    }

    #circle-1 .circle-title {
        display: flex;
        width: fit-content;
    }

    .img {
        transform: unset;
        width: 85px !important;
        height: 30px;
        margin-left: 0px;
        margin-top: 30px;
        left: 0;
    }

    .app-hub .menu-info {
        margin-top: 30px;
        padding-top: 0px;
    }

    .top {
        position: fixed;
        left: 3%;
        z-index: 99;
        opacity: 1 !important;
    }

    #div-logout {
        right: 3%;
        left: unset;
    }

    .span-main-title {
        top: 50%;
        left: 25%;
    }

    .app-hub .circle-title {
        width: 0px;
    }

    .footer {
        font-size: 1.5em;
        color: white;
        position: fixed;
        bottom: 0;
        right: 0;
        text-align: center;
        display: initial;
        width: 100%;
        background: linear-gradient(270deg, rgb(205 176 255) 10%, rgb(135 255 165) 30%, rgb(24 232 255) 80%, rgb(69 77 255) 105%);
        opacity: 1;
        line-height: 50px;
        z-index: 99;
    }

    .footer br {
        display: none;
    }
}
</style>
